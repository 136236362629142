$(document).ready(function(){
    $('[data-toggle="popover"]').popover({
        html: true
    });
});

$(document).on('click', '.exclude-user-from-role', function () {
    var roleID = $(this).data('roleid'),
        userID = $(this).data('userid'),
        _token = $('meta[name=csrf-token]').attr('content');

    $.ajax({
        type: 'POST',
        headers: {
            "X-CSRF-TOKEN": _token
        },
        url: '/profil/user/exclude-user-from-role',
        data: {roleID: roleID, userID: userID},
        success: function(res) {
            if (res.success == true) {
                $('#tableListOfRoles').children('tbody').children('tr[data-roleid=' + roleID + ']').remove();
                $('#alertRoleRemovedSuccess').removeClass('hide');
            } else {
                $('#alertRoleRemovedError').removeClass('hide');
            }
        }
    });

    return false;
});

$('.add-role-to-user').on('click', function () {
    var roleID = $('select[name=availableRoles]').val(),
        userID = $(this).data('userid'),
        _token = $('meta[name=csrf-token]').attr('content');

    $.ajax({
        type: 'POST',
        headers: {
            "X-CSRF-TOKEN": _token
        },
        url: '/profil/user/add-role-to-user',
        data: {roleID: roleID, userID: userID},
        dataType: 'JSON',
        success: function (res) {
            if(res.success == true) {
                // TODO: HTML-Generierung in eigene Funktion auslagern
                var role = JSON.parse(res.role);
                var tableBody = $('#tableListOfRoles').children('tbody');
                var tr = tableBody.children('tr:last-child');

                tableBody.append('<tr class="hide">' + tr.html() + '</tr>');
                tr = tableBody.children('tr:last-child');

                tr.attr('data-roleid', role.id);
                tr.children('td:first-child').text(role.display_name);
                tr.children('td:nth-child(2)').text(role.description);
                tr.children('td:last-child').children('a').attr('data-roleid', role.id);
                tr.removeClass('hide');

                $('#modalAddRole').modal('toggle');
                $('#alertRoleAddedSuccess').removeClass('hide');
            } else {
                $('#modalAddRole').modal('toggle');

                if (res.alertID !== undefined) {
                    $('#' + res.alertID).removeClass('hide');
                } else {
                    $('#alertRoleAddedError').removeClass('hide');
                }
            }
        }
    });

    return false;
});


google.charts.load('current', {'packages':['corechart', 'bar']});


/**
 * Nachrichtenversand quoka
 *
 */
$('.quoka-send-request').on('click', function () {
    var providerID = $(this).data('providerid'),
        fakeEmail  = $(this).data('fakeemail'),
        _token     = $('meta[name=csrf-token]').attr('content'),
        _href      = $(this).attr('href'),
        _this      = this;

    // E-Mail-Adresse in Clipboard kopieren
    var input = $(this).parent().prev('td').prev('td').children('.fake-email-input');
    input.select();
    document.execCommand('copy');

    $.ajax({
        type: 'POST',
        headers: {
            "X-CSRF-TOKEN": _token
        },
        url: '/profil/system/crawling/set-user-to-provider-request',
        data: {providerID: providerID, fakeEmail: fakeEmail},
        success: function(res) {
            if (res == 'success') {
                $(_this).parent().parent().addClass('success');

                window.open (
                    _href,
                    '_blank'
                );
            }
            else if (res == 'already sent') {
                alert('Nachricht wurde bereits versendet!');
            }
        }
    });

    return false;
});

/**
 * Zurücksetzen auf den Ursprung bei quoka
 *
 */
$('.provider-return-to-default').on('click', function () {
    var providerID = $(this).data('providerid'),
        _token     = $('meta[name=csrf-token]').attr('content'),
        _this      = this;

        $.ajax({
            type: 'post',
            headers: {
                'X-CSRF-TOKEN': _token
            },
            url: '/profil/system/crawling/provider-return-to-default',
            data: {providerID: providerID},
            success: function(res) {
                $(_this).parent().parent().removeClass('success');
            }
        });
});

$('.fake-email-input').on('focus', function () {
    $(this).select();
    document.execCommand('copy');
});


// Live-Panel updates
if ($('#analyzesLivePanel').length) {
    updateLivePanel();
    var _updateLivePanel = setInterval(updateLivePanel, 15000);
}

/**
 * updatet das live-panel
 */
var currentUsers = 0,
    tracking     = null;

function updateLivePanel ()
{
    var panel  = $('#analyzesLivePanel'),
        table  = $('#analyzesLivePanelTable').children('tbody'),
        html   = '',
        _token = $('meta[name=csrf-token]').attr('content');

    $.ajax({
        type: 'POST',
        headers: {
            "X-CSRF-TOKEN": _token
        },
        url: '/profil/analyzes/update-live-panel',
        success: function(res) {
            var _tracking = JSON.stringify(res.tracking);

            if (res.currentUsers != currentUsers || _tracking != tracking) {
                panel.children().children('.current-users').children('span').text(res.currentUsers);
                $.each(res.tracking, function (i, item) {
                    html += '<tr>' +
                            '<td>' + item.url + '</td>' +
                            '<td>' + (item.userID > 0 ? item.user.email : 'Gast (' + item.ip + ')') + '</td>' +
                            '<td>' + item.time + '</td>' +
                            '</tr>';
                });

                table.html(html);

                panelUpdate(panel);
            }

            currentUsers = res.currentUsers;
            tracking     = _tracking;
        }
    });
}

/**
 * Führt eine visuelle update-aktion aus
 * Hintergrund ändert kurz die Farbe, damit der User sieht, dass es in diesem Fenster ein Update gab
 *
 * @param panel
 */
function panelUpdate (panel) {
    panel.addClass('update');
    panel.animate({
        backgroundColor: ''
    }, 1500, function () {
        panel.removeClass('update');
    });
}

$(function () {
    $('#datetimepicker1').datetimepicker({
        locale: 'de',
        format: 'YYYY-MM-DD HH:mm'
    });
});